import { useAppDispatch, useAppSelector } from "@store/hooks";
import {
  Box,
  Button,
  Checkbox,
  Grid,
  TextField,
  Typography,
  Chip,
  FormControlLabel,
  makeStyles,
  IconButton,
  Popper,
  FormGroup
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  CustomHeaderPage,
  ArticlesSearch,
  ConfirmAction,
  ConfirmActionPropsData,
  CommonSpeedDial,
  CustomRecordFound,
} from "@components/index";
import Paper from "@material-ui/core/Paper";
import {
  Validation,
  ValidationConfig,
  ValidationTypes,
} from "@utilities/validation";
import { setWarning } from "@store/common/common.effects";
import {
  Filter,
  FilterMethods,
  Filters,
  FilterTypes,
  SortModel,
} from "@store/common/common.types";

import { dateFormatter, scrollFunction } from "@utilities/utilities";
import React, { FunctionComponent, Fragment, useEffect, useState } from "react";
import {
  DataGrid,
  GridColDef,
  GridSortModelParams,
} from "@material-ui/data-grid";
import { getDettaglioScontiConvenzioniDate } from "@store/dettaglio-sconti-convenzioni/dettaglioScontiConvenzioni.effects";
import { DettaglioScontiConvenzioniDate } from "@store/dettaglio-sconti-convenzioni/dettaglioScontiConvenzioni.type";
import { EntiConvenzionati } from "@store/enti-convenzionati/entiConvenzionati.types";
import {
  decurtaCriteriConvenzioniDettagliSconti,
  getCriteriConvenzioniDettagliSconti,
  prorogaCriteriConvenzioniDettagliSconti,
  putCriteriConvenzioniDettagliSconti,
  scadenzaMassivaCriteriConvenzioniDettagliSconti,
} from "@store/criteri-convenzioni/criteriConvenzioni.effects";
import { criteriConvenzioniShowDettaglioSconti } from "@store/criteri-convenzioni/criteriConvenzioni.selector";
import { CriteriConvenzioni } from "@store/criteri-convenzioni/criteriConvenzioni.type";
import _ from "lodash";
import {
  SelectDatiAggiuntiviConvenzione,
  SelectEntiConvenzionati,
  SelectProduttori,
  SelectStagionalita,
  SelectTipiArticolo,
} from "@components/selects";
import { resetCriteriConvenzioniShowDettaglioSconti } from "@store/criteri-convenzioni/criteriConvenzioni.actions";
import {
  AllOut,
  ExpandLess,
  Delete,
  ControlPointDuplicate,
  OfflineBolt,
  Schedule,
  AssignmentReturn,
  EventBusy
} from "@material-ui/icons";
import { Articoli } from "@store/articoli/articoli.types";
import {
  globalProduttori,
  globalStagionalita,
  globalTipiArticolo,
} from "@store/global/global.selector";
import { getGlobalProduttori } from "@store/global/global.effects";
import { Produttori } from "@store/produttori/produttori.types";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { CusomDataGridSelctPage } from "@components/custom-datagrid-pagination/CusomDataGridSelctPage";
import { Stagionalita } from "@store/stagionalita/stagionalita.types";
import { TipiArticolo } from "@store/tipi-articolo/tipiArticolo.types";
import environment from "@environment/environment";
import moment from "moment";
import { SelectConvenzioniGeneriche } from "@components/selects/convenzioni-generiche/ConvenzioniGeneriche";
import { DesConvenzioniGeneriche } from "@store/des-convenzioni-generiche/desConvenzioniGeneriche.types";
import { ConvenzioniDatiAggiuntiviPost } from "@store/convenzioni-dati-aggiuntivi/convenzioniDatiAggiuntivi.types";
import { postConvenzioniDatiAggiuntiviAssocia } from "@store/convenzioni-dati-aggiuntivi/convenzioniDatiAggiuntivi.effects";
import { AnagraficaGoodyear } from "@store/anagrafica/anagrafica.types";
import NumberFormat from "react-number-format";
import Auth from "@utilities/auth";
import { resetRicercaArticoli } from "@store/articoli/articoli.actions";
import { ProrogaConvenzioniDialog } from "./components/ProrogaConvenzioniDialog";
import { ScadenzaMassivaConvenzioniDialog } from "./components/ScadenzaMassivaConvenzioniDialog";
import { DecurtaConvenzioniDialog } from "./components/DecurtaConvenzioniDialog";
import { CustomSelect } from "@components/selects/custom-select/CustomSelect";

const useStyles = makeStyles((theme) => ({
  hiddenCip: {
    maxWidth: "55vw",
    maxHeight: "40px",
    overflow: "hidden",
  },
  showChip: {
    maxWidth: "55vw",
  },
}));

let savePreviusFilters = ""; // save in global variable the privius filters
let savePreviusFiltersCont = 0; // save in global variable the privius filters

interface DataPdf {
  nomePDF: string;
  url: string;
}

interface ConvenzioniAScontoProps {
  pageTitle: string;
  convenzioneKey: string;
  convenzioneFlotteKey?: string;
  isConvenzioneGenerica?: boolean;
  duplicateConvenzione?: boolean;
}

export const ConvenzioniASconto: FunctionComponent<ConvenzioniAScontoProps> = (
  props
) => {
  const {
    pageTitle,
    convenzioneKey,
    convenzioneFlotteKey = null,
    isConvenzioneGenerica = false,
    duplicateConvenzione = false,
  } = props;

  const { defaultTipoArticolo } = environment.defaultFiltriConvenzioni;
  const { defaultStagionalita } = environment.defaultFiltriConvenzioni;
  const defaultCodiciProduttori = environment.codiciProduttori;
  const classes = useStyles();

  const dispatch = useAppDispatch();
  const { t } = useTranslation(["common", "convenzioniScontiDetailPage"]);
  const [errors, setErrors] = useState({} as { [key: string]: string });

  const [produttoriPopperOpen, setProduttoriPopperOpen] = useState(false);
  const [produttoriPopperAnchorEl, setproduttoriPopperAnchorEl] = React.useState<null | HTMLElement>(null);
  const [progConvenzione, setProgConvenzione] = useState<number>(0);
  const [progConvenzioneFlotte, setProgConvenzioneFlotte] = useState<number>(0);
  const [selectedEnte, setSelectedEnte] = useState<number>(0);

  const [dataConvenzione, setDataConvenzione] = useState(
    {} as DettaglioScontiConvenzioniDate
  );
  const searchedCriteriConvenzioni: CriteriConvenzioni[] = useAppSelector(
    criteriConvenzioniShowDettaglioSconti
  ).data;
  const produttori: Produttori[] = useAppSelector(globalProduttori);
  const [criteriConvenzioni, setCriteriConvenzioni] = useState(
    [] as CriteriConvenzioni[]
  );
  const [selectedStagionalita, setSelectedStagionalita] = useState(0);
  const [selectedProduttori, setSelectedProduttori] = useState([] as any[]);
  const [selectedTipiArticolo, setSelectedTipiArticolo] = useState([] as any[]);
  const [pageSize, setPagesize] = useState(100);
  const [pageNumber, setPageNumber] = useState(0);

  const stagionalita: Stagionalita[] = useAppSelector(globalStagionalita);
  const codTipoArticolo: TipiArticolo[] = useAppSelector(globalTipiArticolo);
  const [gridKey, setGridKey] = useState(0);
  const [chipShow, setChipShow] = useState(false);
  const [selectedArticles, setSelectedArticles] = useState([] as Articoli[]);
  const [confirmAction, setConfirmAction] = useState<ConfirmActionPropsData>({
    isOpen: false,
  });
  const [dataPdf, setDataPdf] = useState<DataPdf | undefined>();
  const [selectedDealers, setSelectedDealers] = useState<AnagraficaGoodyear[]>(
    []
  );
  const [confirmDialogeOpen, setConfirmDialogeOpen] = useState(false);
  const [prorogaConvenzioniOpen, setProrogaConvenzioniOpen] = useState(false);
  const [decurtaConvenzioniOpen, setDecurtaConvenzioniOpen] = useState(false);
  const [scadenzaMassivaConvenzioniOpen, setScadenzaMassivaConvenzioniOpen] = useState(false);

  const [showExtraSconti, setShowExtraSconti] = useState(false);
  const [showExtraScontiStagionalita, setShowExtraScontiStagionalita] = useState<number[]>([]);
  const [showEscludiArticoliFuoriProduzione, setShowEscludiArticoliFuoriProduzione] = useState(false);

  const [sortColumns, setSortColumns] = useState<SortModel[]>([]);

  useEffect(() => {
    if (selectedProduttori.length > 0
      || selectedTipiArticolo.length > 0
      || selectedStagionalita !== 0
      || selectedArticles.length > 0
    ) {
      setShowExtraSconti(false);
      setShowExtraScontiStagionalita([]);
      setShowEscludiArticoliFuoriProduzione(false);
    }
  }, [selectedProduttori, selectedTipiArticolo, selectedStagionalita, selectedArticles]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (showExtraSconti) {
      setSelectedStagionalita(0);
      setSelectedProduttori([]);
      setSelectedTipiArticolo([]);
      setSelectedArticles([]);
    } else {
      setShowExtraScontiStagionalita([]);
      setShowEscludiArticoliFuoriProduzione(false);
    }
  }, [showExtraSconti]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // executed on page leve to empty store
    return () => {
      resetDatagird();
      dispatch(resetRicercaArticoli())
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (produttori.length < 1) {
      dispatch(getGlobalProduttori());
    }
  }, [produttori]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let resData: any[] = [];
    if (searchedCriteriConvenzioni) {
      // loop and if not empty extract the data structure if empty create a new dettaglioScontiConvenzioni
      resData = searchedCriteriConvenzioni
        // Se sto cercando per i produttori, escludi i criteri sui singoli articoli
        .filter((item: { [key: string]: any }) => {
          if (
            (selectedStagionalita !== 0 ||
              selectedTipiArticolo.length > 0 ||
              selectedProduttori.length > 0) &&
            item.codArticolo
          ) {
            return false;
          } else {
            return true;
          }
        })
        .map((item: { [key: string]: any }) => {
          let arrItem: any[] = [];
          if (!_.isEmpty(item.dettaglioScontiConvenzioni)) {
            arrItem = item.dettaglioScontiConvenzioni.map((element: any) => {
              let newItems: { [key: string]: any } = { ...item };
              newItems["dettaglioScontiConvenzioni"] = [{ ...element }];
              return { ...newItems };
            });
          } else {
            if (
              !(
                selectedStagionalita === 0 &&
                selectedTipiArticolo.length === 0 &&
                selectedProduttori.length === 0
              )
            ) {
              let newConvenzione: { [key: string]: any } = { ...item };
              newConvenzione["dettaglioScontiConvenzioni"] = [
                {
                  progConvenzione: progConvenzione,
                  progCriterio: item.progCriterio,
                  progSconto: 0,
                  tipoSconto: "S",
                  percSconto: 0,
                  dataInizioValidita: dataConvenzione.dataInizioValidita,
                  dataFineValidita: dataConvenzione.dataFineValidita,
                },
              ];
              arrItem.push(newConvenzione);
            }
          }
          return arrItem;
        });
      //TODO : test this
      if (
        selectedTipiArticolo.length === 0 &&
        selectedProduttori.length > 0
      ) {
        if (selectedStagionalita !== 0) {
          let ret = crateNewDataTipoArticolo();
          resData.push(ret);
        }
      } else {
        if (
          selectedStagionalita === 0 &&
          selectedTipiArticolo.length > 0 &&
          selectedProduttori.length > 0
        ) {
          //Bug #26540 --criterio trasporto pesante su convenzione
          if (
            ! selectedTipiArticolo.includes(environment.codiciTipiArticolo.trasportoPesante) ||
            !defaultCodiciProduttori.some((value) => {
              return selectedProduttori.includes(value);
            })
          ) {
            let ret = crateNewDataStagionalita();
            resData.push(ret);
          } else if (resData.length === 0) {
            selectedProduttori.forEach((codProduttore) => {
              selectedTipiArticolo.forEach((tipiArticolo) => {
                // gate name of produttore
                let name = produttori.find(
                  (produttori) => produttori.codProduttore === codProduttore
                );
                let tipoArticoloDescrizione = codTipoArticolo.find(
                  (tipoArticolo) =>
                    tipoArticolo.codTipoArticolo === tipiArticolo
                );
                let newConvenzione: CriteriConvenzioni = {
                  codArticolo: "",
                  codCategoriaMerc: 0,
                  codDisegno: 0,
                  codDivisione: 1,
                  codIndiceCarico: 0,
                  codLarghezzaCerchio: 0,
                  codLarghezzaNominale: 0,
                  codProduttore: codProduttore,
                  codSerie: 0,
                  codStagionalita: 0,
                  codTipoArticolo: tipiArticolo || 0,
                  codVelocita: 0,
                  dataOperazione: "",
                  descrizioneProduttore: name?.descrizione,
                  descrizioneStagionalita: "",
                  descrizioneTipoArticolo: tipoArticoloDescrizione?.descrizione,
                  dettaglioScontiConvenzioni: [
                    {
                      progConvenzione: progConvenzione,
                      progCriterio: 0,
                      progSconto: 0,
                      tipoSconto: "S",
                      percSconto: 0,
                      dataInizioValidita: dataConvenzione.dataInizioValidita,
                      dataFineValidita: dataConvenzione.dataFineValidita,
                    },
                  ],
                  progConvenzione: progConvenzione,
                  progCriterio: 0,
                  tipoOperazione: "",
                  utente: "",
                };
                resData.push([newConvenzione]);
              });
            });
          }
          //-----------------------------------
        } else {
          if (selectedProduttori.length > 0) {
            // if to check if we need to carate produttori ore articoli
            // loop selected produttori and crate structure
            let stagionalitaDescrizione = stagionalita.find(
              (stagionalita) =>
                stagionalita.codStagionalita === selectedStagionalita
            );

            selectedProduttori.forEach((codProduttore) => {
              selectedTipiArticolo.forEach((tipiArticolo) => {
                  let tipoArticoloDescrizione = codTipoArticolo.find(
                    (tipoArticolo) =>
                      tipoArticolo.codTipoArticolo === tipiArticolo
                  );
                let convenzione = searchedCriteriConvenzioni.find(
                  (convenzioneData) => {
                    return convenzioneData.codProduttore === codProduttore
                      && convenzioneData.codTipoArticolo === tipiArticolo;
                  }
                );
                if (convenzione) {
                  return;
                } else {
                  // gate name of produttore
                  let name = produttori.find(
                    (produttori) => produttori.codProduttore === codProduttore
                  );
                  let newConvenzione: CriteriConvenzioni = {
                    codArticolo: "",
                    codCategoriaMerc: 0,
                    codDisegno: 0,
                    codDivisione: 1,
                    codIndiceCarico: 0,
                    codLarghezzaCerchio: 0,
                    codLarghezzaNominale: 0,
                    codProduttore: codProduttore,
                    codSerie: 0,
                    codStagionalita: selectedStagionalita || 0,
                    codTipoArticolo: tipiArticolo || 0,
                    codVelocita: 0,
                    dataOperazione: "",
                    descrizioneProduttore: name?.descrizione,
                    descrizioneStagionalita: stagionalitaDescrizione?.descrizione,
                    descrizioneTipoArticolo: tipoArticoloDescrizione?.descrizione,
                    dettaglioScontiConvenzioni: [
                      {
                        progConvenzione: progConvenzione,
                        progCriterio: 0,
                        progSconto: 0,
                        tipoSconto: "S",
                        percSconto: 0,
                        dataInizioValidita: dataConvenzione.dataInizioValidita,
                        dataFineValidita: dataConvenzione.dataFineValidita,
                      },
                    ],
                    progConvenzione: progConvenzione,
                    progCriterio: 0,
                    tipoOperazione: "",
                    utente: "",
                  };
                  resData.push([newConvenzione]);
                }
              });
            });
          } else {
            // loop selected articles and crate structure
            selectedArticles.forEach((articolo) => {
              let convenzione = _.orderBy(
                searchedCriteriConvenzioni,
                ["progCriterio"],
                ["desc"]
              ).find((convenzioneData) => {
                return convenzioneData.codArticolo === articolo.codArticolo;
              });

              if (convenzione) {
                if (convenzione.dettaglioScontiConvenzioni.length === 0) {
                  let duplicatedConvenzione: { [key: string]: any } = {
                    ...convenzione,
                  };
                  duplicatedConvenzione["dettaglioScontiConvenzioni"] = [
                    {
                      progConvenzione: progConvenzione,
                      progCriterio: convenzione.progCriterio,
                      progSconto: 0,
                      tipoSconto: "S",
                      percSconto: 0,
                      dataInizioValidita: dataConvenzione.dataInizioValidita,
                      dataFineValidita: dataConvenzione.dataFineValidita,
                    },
                  ];
                  resData.push([duplicatedConvenzione]);
                } else {
                  return;
                }
              } else {
                let newConvenzione: CriteriConvenzioni = {
                  codArticolo: articolo.codArticolo,
                  codCategoriaMerc: 0,
                  codDisegno: 0,
                  codDivisione: 1,
                  codIndiceCarico: 0,
                  codLarghezzaCerchio: 0,
                  codLarghezzaNominale: 0,
                  codProduttore: 0,
                  codSerie: 0,
                  codStagionalita: 0,
                  codTipoArticolo: 0,
                  codVelocita: 0,
                  dataOperazione: "",
                  descrizioneArticolo: articolo.descrizione,
                  dettaglioScontiConvenzioni: [
                    {
                      progConvenzione: progConvenzione,
                      progCriterio: 0,
                      progSconto: 0,
                      tipoSconto: "S",
                      percSconto: 0,
                      dataInizioValidita: dataConvenzione.dataInizioValidita,
                      dataFineValidita: dataConvenzione.dataFineValidita,
                    },
                  ],
                  progConvenzione: progConvenzione,
                  progCriterio: 0,
                  tipoOperazione: "",
                  utente: "",
                };
                resData.push([newConvenzione]);
              }
            });
          }
        }
      }

      let merged: any[] = [];
      //loop array of array and create a usable stcuture fore the DataGrid
      resData.forEach((element) => {
        element.forEach((item: any) => {
          merged.push(item);
        });
      });

      const convenzioni = _.orderBy(merged,
        [
          i => {
            // le convenzioni sugli articoli devono andare per ultime
            return i.codArticolo ? 'zzzzzzzzzzzzzzzz' : i.descrizioneProduttore
          },
          i => i.descrizioneStagionalita,
          i => i.descrizioneTipoArticolo,
          i => i.codArticolo,
      ]);

      setCriteriConvenzioni(() => {
        return convenzioni;
      });
    } else {
      // if criteri data is undefined
      setCriteriConvenzioni([]);
    }
  }, [searchedCriteriConvenzioni]); // eslint-disable-line react-hooks/exhaustive-deps

  const crateNewDataStagionalita = () => {
    let retArr: any = [];
    let codiciStagionalita = defaultStagionalita; // co0dici per stagiolalità inverno estate
    let contStagionalita = 0;
    let found: any = [];

    selectedProduttori.forEach((codProduttore) => {
      selectedTipiArticolo.forEach((tipiArticolo) => {
        if (searchedCriteriConvenzioni.length >= 1) {
          // finde which sesons ther are fore a certen producer
          searchedCriteriConvenzioni.forEach((conv) => {
            do {
              if (
                conv.codProduttore === codProduttore &&
                conv.codTipoArticolo === tipiArticolo &&
                conv.codStagionalita === codiciStagionalita[contStagionalita]
              ) {
                found.push(codiciStagionalita[contStagionalita]);
              }
              contStagionalita = contStagionalita + 1;
            } while (contStagionalita !== codiciStagionalita.length);
            contStagionalita = 0; // reset cont
          });
        }

        if (found.length === codiciStagionalita.length) {
          found = [];
          return; // if ther is a produttore and has 2 stagionalità
        } else {
          // gate name of produttore
          codiciStagionalita.forEach((codStagionalita) => {
            // eslint-disable-next-line
            let index = found.findIndex((nFound: number) => {
              // skip if ther is alredy a covnenzione with that cos stagionalità
              if (nFound === codStagionalita) {
                return codStagionalita;
              }
            });
            if (index === -1) {
              let stagionalitaDescrizione = stagionalita.find(
                (stagionalita) => stagionalita.codStagionalita === codStagionalita
              );
              let tipoArticoloDescrizione = codTipoArticolo.find(
                (tipoArticolo) =>
                  tipoArticolo.codTipoArticolo === tipiArticolo
              );
              let name = produttori.find(
                (produttori) => produttori.codProduttore === codProduttore
              );
              let newConvenzione: CriteriConvenzioni = {
                codArticolo: "",
                codCategoriaMerc: 0,
                codDisegno: 0,
                codDivisione: 1,
                codIndiceCarico: 0,
                codLarghezzaCerchio: 0,
                codLarghezzaNominale: 0,
                codProduttore: codProduttore,
                codSerie: 0,
                codStagionalita: codStagionalita,
                codTipoArticolo: tipiArticolo,
                codVelocita: 0,
                dataOperazione: "",
                descrizioneProduttore: name?.descrizione,
                descrizioneStagionalita: stagionalitaDescrizione?.descrizione,
                descrizioneTipoArticolo: tipoArticoloDescrizione?.descrizione,
                dettaglioScontiConvenzioni: [
                  {
                    progConvenzione: progConvenzione,
                    progCriterio: 0,
                    progSconto: 0,
                    tipoSconto: "S",
                    percSconto: 0,
                    dataInizioValidita: dataConvenzione.dataInizioValidita,
                    dataFineValidita: dataConvenzione.dataFineValidita,
                  },
                ],
                progConvenzione: progConvenzione,
                progCriterio: 0,
                tipoOperazione: "",
                utente: "",
              };
              retArr.push(newConvenzione);
            } else {
              console.log("skip");
            }
          });
          found = []; // reset found stagionalità to empty
        }
      });
    });
    return retArr;
  };

  const crateNewDataTipoArticolo = () => {
    let retArr: any = [];
    let codiciTipoArticolo = defaultTipoArticolo;
    let contTipoArticolo = 0;
    let found: any = [];

    selectedProduttori.forEach((codProduttore) => {
      if (searchedCriteriConvenzioni.length >= 1) {
        // finde which sesons ther are fore a certen producer
        searchedCriteriConvenzioni.forEach((conv) => {
          do {
            if (
              conv.codProduttore === codProduttore &&
              conv.codTipoArticolo === codiciTipoArticolo[contTipoArticolo]
            ) {
              found.push(codiciTipoArticolo[contTipoArticolo]);
            }
            contTipoArticolo = contTipoArticolo + 1;
          } while (
            contTipoArticolo !== codiciTipoArticolo.length &&
            contTipoArticolo < defaultTipoArticolo.length + 1
          );
          contTipoArticolo = 0; // reset cont
        });
      }

      if (found.length === codiciTipoArticolo.length) {
        found = [];
        return; // if ther is a produttore and has 2 stagionalità
      } else {
        // gate name of produttore
        codiciTipoArticolo.forEach((codiceTipoArticolo) => {
          // eslint-disable-next-line
          let index = found.findIndex((nFound: number) => {
            // skip if ther is alredy a covnenzione with that cos stagionalità
            if (nFound === codiceTipoArticolo) {
              return codiceTipoArticolo;
            }
          });
          if (index === -1) {
            let stagionalitaDescrizione = stagionalita.find(
              (stagionalita) =>
                stagionalita.codStagionalita === selectedStagionalita
            );
            let tipoArticoloDescrizione = codTipoArticolo.find(
              (tipoArticolo) =>
                tipoArticolo.codTipoArticolo === codiceTipoArticolo
            );
            let name = produttori.find(
              (produttori) => produttori.codProduttore === codProduttore
            );
            let newConvenzione: CriteriConvenzioni = {
              codArticolo: "",
              codCategoriaMerc: 0,
              codDisegno: 0,
              codDivisione: 1,
              codIndiceCarico: 0,
              codLarghezzaCerchio: 0,
              codLarghezzaNominale: 0,
              codProduttore: codProduttore,
              codSerie: 0,
              codStagionalita: selectedStagionalita,
              codTipoArticolo: codiceTipoArticolo,
              codVelocita: 0,
              dataOperazione: "",
              descrizioneProduttore: name?.descrizione,
              descrizioneStagionalita: stagionalitaDescrizione?.descrizione,
              descrizioneTipoArticolo: tipoArticoloDescrizione?.descrizione,
              dettaglioScontiConvenzioni: [
                {
                  progConvenzione: progConvenzione,
                  progCriterio: 0,
                  progSconto: 0,
                  tipoSconto: "S",
                  percSconto: 0,
                  dataInizioValidita: dataConvenzione.dataInizioValidita,
                  dataFineValidita: dataConvenzione.dataFineValidita,
                },
              ],
              progConvenzione: progConvenzione,
              progCriterio: 0,
              tipoOperazione: "",
              utente: "",
            };
            retArr.push(newConvenzione);
          } else {
            console.log("skip");
          }
        });
        found = []; // reset found stagionalità to empty
      }
    });
    return retArr;
  };

  useEffect(() => {
    scrollFunction();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    //Reset filters on codice aritocolo
    if (
      selectedArticles.length > 0 &&
      (selectedStagionalita !== 0 ||
        selectedTipiArticolo.length > 0 ||
        selectedProduttori.length > 0)
    ) {
      setSelectedStagionalita(0);
      setSelectedProduttori([]);
      setSelectedTipiArticolo([]);
    }
  }, [selectedArticles]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangeDate = (data: any) => {
    if (selectedEnte !== undefined) {
      // if (dataConvenzione.dataInizioValidita !== undefined &&
      //     data.id === 'dataFineValidita' &&
      //     moment(dataConvenzione.dataInizioValidita) > moment(data.value)) {

      //     dispatch(setWarning({ message: 'Data fine validità maggiore della data di inzio validità' }));

      // } else {
      setDataConvenzione((prevState) => {
        return {
          ...prevState,
          [data.id]: data.value,
        };
      });
      // }
    } else {
      dispatch(setWarning({ message: "Seleziona una convenzione prima" }));
    }
  };
  const resetFiltriConv = () => {
    resetDatagird();

    setSelectedStagionalita(0);
    setSelectedProduttori([]);
    setSelectedTipiArticolo([]);
    setShowExtraSconti(false);
    setShowExtraScontiStagionalita([]);
    setShowEscludiArticoliFuoriProduzione(false);
  };

  const validate = () => {
    // validate before calling api
    const fieldsConfig: ValidationConfig[] = [
      Validation.buildFieldConfig(
        "progConvenzione",
        progConvenzione ? progConvenzione : undefined,
        [ValidationTypes.REQUIRED]
      ),
      Validation.buildFieldConfig(
        "dataInizioValidita",
        dataConvenzione.dataInizioValidita
          ? dataConvenzione.dataInizioValidita
          : undefined,
        [ValidationTypes.REQUIRED]
      ),
      Validation.buildFieldConfig(
        "dataFineValidita",
        dataConvenzione.dataFineValidita
          ? dataConvenzione.dataFineValidita
          : undefined,
        [ValidationTypes.REQUIRED]
      ),
    ];

    const validationErrors = Validation.validate(fieldsConfig);
    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const handleChangeStagionalita = (value: number, id: string) => {
    resetDatagird();
    setSelectedStagionalita(value || 0);
  };

  const resetDatagird = () => {
    savePreviusFilters = ""; // Reset criteri filters
    savePreviusFiltersCont = 0;
    dispatch(resetCriteriConvenzioniShowDettaglioSconti());
  };

  const handleChangeEnte = async (progConvenzione: number) => {
    resetFiltriConv();
    setProgConvenzione(progConvenzione);
    if (progConvenzione) {
      let { payload }: any = await dispatch(
        getDettaglioScontiConvenzioniDate(progConvenzione.toString())
      );
      let formatDate: DettaglioScontiConvenzioniDate = {
        dataFineValidita:
          dateFormatter(new Date(payload.dataFineValidita)) || "",
        dataInizioValidita:
          dateFormatter(new Date(payload.dataInizioValidita)) || "",
      };
      setDataConvenzione(() => {
        return formatDate;
      });
    }
  };

  const handleProrogaConvenzioni = async (date: string) => {

    if (! moment(date).isAfter(moment())) {
      dispatch(setWarning({ message: "La data selezionata deve essere maggiore di oggi" }));
      return;
    }

    const filter = getFilters();
    setProrogaConvenzioniOpen(false);
    await dispatch(
      prorogaCriteriConvenzioniDettagliSconti(
        progConvenzione.toString(),
        date,
        filter
      )
    );

    handleChangeDate({
      id: 'dataFineValidita',
      value: date
    });

    search(date);
  }

  const handleScadenzaMassivaConvenzioni = async (date: string) => {

    if (! moment(date).isAfter(moment())) {
      dispatch(setWarning({ message: "La data selezionata deve essere maggiore di oggi" }));
      return;
    }

    if (! moment(date).isBefore(moment(dataConvenzione.dataFineValidita))) {
      dispatch(setWarning({ message: "La data selezionata deve essere minore della data fine validità della convenzione" }));
      return;
    }
  
    const filter = getFilters();
    setScadenzaMassivaConvenzioniOpen(false);
    await dispatch(
      scadenzaMassivaCriteriConvenzioniDettagliSconti(
        progConvenzione.toString(),
        date,
        filter
      )
    );

    search(date);
  }

  const handleDecurtaConvenzioni = async (value: number) => {

    if (value <= 0) {
      dispatch(setWarning({ message: "L'importo deve essere maggiore di zero" }));
      return;
    }

    const filter = getFilters();
    setDecurtaConvenzioniOpen(false);
    await dispatch(
      decurtaCriteriConvenzioniDettagliSconti(
        progConvenzione.toString(),
        value,
        filter
      )
    );

    search();
  }

  const handleChangeProduttori = (values: any, id: string) => {
    resetDatagird();
    setSelectedProduttori(values);
  };

  const handleChangeTipiArticolo = (values: any, id: string) => {
    resetDatagird();
    setSelectedTipiArticolo(values);
  };

  const search = (dataFine?: string) => {
    if (
      dataConvenzione.dataInizioValidita !== undefined &&
      moment(dataConvenzione.dataInizioValidita) >
        moment(dataConvenzione.dataFineValidita)
    ) {
      dispatch(
        setWarning({
          message: "Data fine validità minore della data di fine validità",
        })
      );
      return;
    }

    savePreviusFilters = ""; // Reset criteri filters
    savePreviusFiltersCont = 0;


    if (validate()) {
      const filter = getFilters(dataFine);

      setSortColumns([]);
      setPageNumber(0);
      dispatch(
        getCriteriConvenzioniDettagliSconti(
          progConvenzione.toString(),
          undefined,
          undefined,
          filter
        )
      );
    }
  };

  const getFilters = (dataFine?: string) => {
    let articles: string[] = [];
    if (selectedArticles.length >= 1) {
      selectedArticles.forEach((article) => {
        articles.push(article.codArticolo);
      });
    }

    const CodTipoArticolo = new Filter(
      FilterTypes.AND,
      "CodTipoArticolo",
      FilterMethods.IN,
      selectedTipiArticolo.toString()
    );
    const CodStagionalita = new Filter(
      FilterTypes.AND,
      "CodStagionalita",
      FilterMethods.EQUAL,
      selectedStagionalita !== 0 ? selectedStagionalita.toString() : ''
    );
    const CodProduttore = new Filter(
      FilterTypes.AND,
      "CodProduttore",
      FilterMethods.IN,
      selectedProduttori.toString()
    );
    const CodArticolo = new Filter(
      FilterTypes.AND,
      "CodArticolo",
      FilterMethods.EQUALIN,
      articles.toString()
    );
    const dataValiditaInizio = new Filter(
      FilterTypes.AND,
      "dataInizioValidita",
      FilterMethods.GREATERTHANOREQUALTO,
      dataConvenzione.dataInizioValidita
    );
    const dataValiditaFino = new Filter(
      FilterTypes.AND,
      "dataFineValidita",
      FilterMethods.LESSTHANOREQUALTO,
      dataFine ? dataFine : dataConvenzione.dataFineValidita
    );
    const codDivisione = new Filter(
      FilterTypes.AND,
      "codDivisione",
      FilterMethods.EQUAL,
      "1"
    );

    const filter: Filters = {
      CodTipoArticolo,
      CodStagionalita,
      CodProduttore,
      dataValiditaInizio,
      dataValiditaFino,
      codDivisione,
      CodArticolo,
    };

    if (showExtraSconti) {
      filter['showExtraSconti'] = new Filter(
        FilterTypes.AND,
        "OnlyExtraSconti",
        '',
        "true"
      );
      if (showExtraScontiStagionalita.length > 0) {
        filter['ExtraScontiStagionalita'] = new Filter(
          FilterTypes.AND,
          "ExtraScontiStagionalita",
          '',
          showExtraScontiStagionalita.join(',')
        );
      }
      if (showEscludiArticoliFuoriProduzione) {
      filter['showEscludiArticoliFuoriProduzione'] = new Filter(
        FilterTypes.AND,
        "ExtraScontiEscludiArticoliFuoriProduzione",
        '',
        "true"
      );
      }
    }

    return filter;
  }

  const spredValue = () => {
    //spred the value of the 1 colum in Datagrid to all
    if (criteriConvenzioni.length > 0) {
      if (
        criteriConvenzioni[0].dettaglioScontiConvenzioni[0].percSconto !== 0
      ) {
        let value = _.cloneDeep(
          criteriConvenzioni[0].dettaglioScontiConvenzioni[0].percSconto
        );
        let setter: any[] = [];
        criteriConvenzioni.forEach((element: any) => {
          if (element.dettaglioScontiConvenzioni[0]?.percSconto !== undefined) {
            element.dettaglioScontiConvenzioni[0].percSconto = value;
          }
          setter.push(element);
        });
        setCriteriConvenzioni(setter);
      } else {
        dispatch(
          setWarning({ message: "Prima cella della tabella non valorizzata" })
        );
      }
    } else {
      dispatch(
        setWarning({
          message: "Non puoi usare questa funzione in questo momento",
        })
      );
    }
  };

  const handleScontoUpdate = (value: any, row: any) => {
    // do Object comparason
    function findeValue(param: any) {
      if (_.isEqual(param, row)) {
        return param;
      }
    }
    // finde value in array and its index and modify it
    const result = criteriConvenzioni.find(findeValue);
    const index = criteriConvenzioni.findIndex(findeValue);
    if (result && result.dettaglioScontiConvenzioni) {
      result.dettaglioScontiConvenzioni[0].percSconto =
        value === "" ? (value = 0) : parseFloat(value);

      let tempData = criteriConvenzioni;
      tempData[index] = { ...result };

      setCriteriConvenzioni(() => {
        return [...tempData];
      });
    }
  };

  const handleChangeEndDate = (value: string, row: any) => {
    function findValue(param: any) {
      if (_.isEqual(param, row)) {
        return param;
      }
    }
    function findDuplicate(param: any) {
      // long if to avoid using object comparison
      if (
        param.progConvenzione ===
          row.dettaglioScontiConvenzioni[0].progConvenzione &&
        param.progCriterio === row.dettaglioScontiConvenzioni[0].progCriterio &&
        row.dettaglioScontiConvenzioni[0].progSconto === 0
      ) {
        return param;
      }
    }

    if (row.dettaglioScontiConvenzioni[0].dataFineValidita === value) return;

    const isSplit = criteriConvenzioni.findIndex(findDuplicate); // you can't duplicate 2 times a line if you have no codSconto
    if (isSplit === -1) {
      const result = criteriConvenzioni.find(findValue);
      const index = criteriConvenzioni.findIndex(findValue);
      if (result && result.dettaglioScontiConvenzioni) {
        if (
          moment(result?.dettaglioScontiConvenzioni[0].dataFineValidita).format(
            "yyyy-MM-DD"
          ) === moment(value).format("yyyy-MM-DD")
        )
          return;

        if (
          checkValiditiDate(
            value,
            result.dettaglioScontiConvenzioni[0].dataInizioValidita
          )
        ) {
          // add new date to current row
          let tempConvData = criteriConvenzioni;
          const currentRowData = _.cloneDeep(result); // this to rm reference to result object

          currentRowData.dettaglioScontiConvenzioni[0].dataFineValidita = value;
          tempConvData[index] = { ...currentRowData };
          //copy the row and create a new one
          if (value < result.dettaglioScontiConvenzioni[0].dataFineValidita) {
            const newRowData = addSecondLine(_.cloneDeep(result), value); // this to rm reference to result object
            tempConvData.splice(index + 1, 0, newRowData); // add the new row under the current one
          }

          setCriteriConvenzioni(() => {
            return [...tempConvData];
          });
        } else {
          dispatch(
            setWarning({ message: `${t("common:message:invalidDate")}` })
          );
          setGridKey((prevState) => {
            return prevState + 1;
          }); // update the gid to set the defValue of the date
        }
      }
    } else {
      dispatch(
        setWarning({
          message: `${t("common:message:imposibleSplitWithNoSave")}`,
        })
      );
      setGridKey((prevState) => {
        return prevState + 1;
      }); // update the gid to set the defValue of the date
    }
  };

  const addSecondLine = (rowData: CriteriConvenzioni, startDate: string) => {
    let newStartDate = new Date(startDate);
    newStartDate.setDate(newStartDate.getDate() + 1);

    rowData.dettaglioScontiConvenzioni[0].dataFineValidita =
      dataConvenzione.dataFineValidita;
    rowData.dettaglioScontiConvenzioni[0].dataInizioValidita = newStartDate
      .toISOString()
      .split("T")[0];
    rowData.dettaglioScontiConvenzioni[0].progSconto = 0;
    rowData.dettaglioScontiConvenzioni[0].isDuplicate = true;
    return rowData;
  };

  const checkValiditiDate = (date: string, startDate: string): boolean => {
    const dateCheck = new Date(date);
    //const dateEnd = new Date(dataConvenzione.dataFineValidita)
    const dateStart = new Date(startDate);

    if (dateStart < dateCheck /* && dateEnd > dateCheck*/) {
      return true;
    } else {
      return false;
    }
  };

  const confAction = () => {
    let dealerListCods: string[] = [];
    selectedDealers.forEach((item) => {
      dealerListCods.push(item.codDitta);
    });
    let dataAssocia: ConvenzioniDatiAggiuntiviPost = {};
    dataAssocia.CodDittaList = dealerListCods;
    dataAssocia.nomePDF = dataPdf?.nomePDF;
    dataAssocia.urlLInk = dataPdf?.url;
    dataAssocia.progconvenzione = progConvenzione;

    dispatch(postConvenzioniDatiAggiuntiviAssocia(dataAssocia));
  };

  const columns: GridColDef[] = [
    {
      field: "criteri",
      headerName: `Criteri`,
      width: 300,
      disableColumnMenu: true,
      disableClickEventBubbling: true,
      sortable: false,
      hideSortIcons: true,
      renderCell: (params) => {
        if (params.rowIndex % pageSize === 0) {
          savePreviusFilters = "";
          savePreviusFiltersCont = 0;
        }
        savePreviusFiltersCont = savePreviusFiltersCont + 1;
        let paragoneTeast: string =
          params.row.descrizioneStagionalita +
          params.row.descrizioneTipoArticolo;
        if (savePreviusFilters === paragoneTeast) {
          if (savePreviusFiltersCont >= criteriConvenzioni.length) {
            savePreviusFilters = "";
            savePreviusFiltersCont = 0;
          }
          return <span></span>;
        } else {
          savePreviusFilters = paragoneTeast; //params.row.descrizioneStagionalita + params.row.descrizioneTipoArticolo
          if (savePreviusFiltersCont >= criteriConvenzioni.length) {
            savePreviusFilters = "";
            savePreviusFiltersCont = 0;
          }

          const retString: string[] = [];
          if (params.row.descrizioneStagionalita) {
            retString.push(params.row.descrizioneStagionalita);
          }
          if (params.row.descrizioneTipoArticolo) {
            retString.push(params.row.descrizioneTipoArticolo);
          }

          return <span className={"font-bold"}> {retString.join(" - ")} </span>;
        }
      },
    },
    {
      field: "descrizione",
      headerName: `Descrizione`,
      width: 350,
      disableColumnMenu: true,
      disableClickEventBubbling: true,
      sortable: true,
      hideSortIcons: false,
      valueGetter: (params) => {
        return (
          params.row.descrizioneProduttore ||
          params.row.codArticolo + " - " + params.row.descrizioneArticolo
        );
      },
    },

    {
      field: "dataInizioValidita",
      headerName: `Data inizio`,
      width: 300,
      disableColumnMenu: true,
      disableClickEventBubbling: true,
      sortable: false,
      hideSortIcons: true,

      renderCell: (params) => {
        return (
          <TextField
            type="date"
            variant="standard"
            disabled={true}
            value={
              params.row.dettaglioScontiConvenzioni[0].dataInizioValidita
                ? dateFormatter(
                    new Date(
                      params.row.dettaglioScontiConvenzioni[0].dataInizioValidita
                    )
                  )
                : ""
            }
            InputLabelProps={{}}
          />
        );
      },
    },
    {
      field: "dataFineValidita",
      headerName: `Data fine`,
      width: 300,
      disableColumnMenu: true,
      disableClickEventBubbling: true,
      sortable: false,
      hideSortIcons: true,

      renderCell: (params) => {
        return (
          <TextField
            key={params.row.dettaglioScontiConvenzioni[0].dataFineValidita}
            type="date"
            variant="standard"
            disabled={!Auth.hasAccess("save-convenzioni")}
            defaultValue={
              params.row.dettaglioScontiConvenzioni[0].dataFineValidita
                ? dateFormatter(
                    new Date(
                      params.row.dettaglioScontiConvenzioni[0].dataFineValidita
                    )
                  )
                : ""
            }
            onBlur={(event) =>
              handleChangeEndDate(event.target.value, params.row)
            }
            InputLabelProps={{}}
          />
        );
      },
    },
    {
      field: "percSconto",
      headerName: `Sconto`,
      flex: 1,
      disableColumnMenu: true,
      disableClickEventBubbling: true,
      sortable: false,
      hideSortIcons: true,
      renderCell: (params) => {
        // return <TextField
        //     type='number'
        //     variant="standard"
        //     value={params.row.dettaglioScontiConvenzioni[0].percSconto}
        //     onChange={(event) => handleScontoUpdate(event.target.value, params.row)}
        //     InputLabelProps={{

        //     }}

        // />
        return (
          <NumberFormat
            decimalScale={2}
            fixedDecimalScale={true}
            decimalSeparator="."
            value={params.row.dettaglioScontiConvenzioni[0].percSconto}
            className={"text-base bg-transparent outline-hidden	pt-3"}
            style={{ outline: "none" }}
            disabled={!Auth.hasAccess("save-convenzioni")}
            // onBlur={(event: any) => handleScontoUpdate(event.target.value, params.row)}
            onChange={(event: any) =>
              handleScontoUpdate(event.target.value, params.row)
            }
          />
        );
      },
    },
  ];

  if (Auth.hasAccess("save-convenzioni")) {
    columns.push({
      field: "delete",
      headerName: `Elimina`,
      width: 100,
      disableColumnMenu: true,
      disableClickEventBubbling: true,
      sortable: false,
      hideSortIcons: true,
      renderCell: (params) => {
        return params.row.dettaglioScontiConvenzioni[0].isDuplicate ? (
          <IconButton
            style={{
              border: "none",
              outline: "none",
            }}
            color="primary"
            size="small"
            onClick={() => {
              var tmpConvData = [...criteriConvenzioni]; // make a separate copy of the array
              var index = tmpConvData.findIndex(
                (x) =>
                  x.progCriterio === params.row.progCriterio &&
                  x.dettaglioScontiConvenzioni[0].progSconto ===
                    params.row.dettaglioScontiConvenzioni[0].progSconto &&
                  x.progConvenzione === params.row.progConvenzione &&
                  x.codProduttore === params.row.codProduttore &&
                  x.codArticolo === params.row.codArticolo &&
                  x.codStagionalita === params.row.codStagionalita &&
                  x.codTipoArticolo === params.row.codTipoArticolo &&
                  x.dettaglioScontiConvenzioni[0].isDuplicate
              );
              if (index !== -1) {
                tmpConvData.splice(index, 1);
                setCriteriConvenzioni(tmpConvData);
              }
            }}
          >
            <Delete />
          </IconButton>
        ) : (
          <div></div>
        );
      },
    });
  }

  const handleSave = async () => {
    let confirmCheckbox = false;
    const clickCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
      confirmCheckbox = e.target.checked;
    };
    let customContent: JSX.Element | undefined = undefined;
    if (duplicateConvenzione) {
      customContent = (
        <FormControlLabel
          control={<Checkbox color="primary" onChange={clickCheckbox} />}
          label={`${t("convenzioniScontiDetailPage:allert:duplicate")}`}
        />
      );
    }
    setConfirmAction({
      isOpen: true,
      title: `${t("common:message:defaultConfirmationTitle")}`,
      subtitle: `${t("common:message:defaultConfirmationMessage")}`,
      confirmAction: async () => {
        if (validate()) {
          dispatch(
            putCriteriConvenzioniDettagliSconti({
              progConvenzine: progConvenzione,
              data: _.cloneDeep(criteriConvenzioni),
              duplicate: confirmCheckbox,
              progFlotte: progConvenzioneFlotte,
            })
          )
            .unwrap()
            .then(() => {
              search();
            });
        }
      },
      customContent: customContent,
    });
  };

  const handleArticoliSelezionati = (articles: Articoli[]) => {
    resetDatagird();
    let tempSelected = selectedArticles.concat(articles);
    // eliminate duplicated values
    var mySet = new Set(tempSelected);
    tempSelected = Array.from(mySet);
    setSelectedArticles(() => {
      return tempSelected;
    });
  };

  const handleDeleteChip = (codArticolo: string) => {
    resetDatagird();
    let tempCodArt = selectedArticles;
    let index = tempCodArt.findIndex((article: Articoli) => {
      return article.codArticolo === codArticolo;
    });
    tempCodArt.splice(index, 1);

    setSelectedArticles(() => {
      return [...tempCodArt];
    });
  };

  const openDuplucate = () => {
    if (progConvenzione) {
      setConfirmDialogeOpen(true);
    } else {
      dispatch(setWarning({ message: "Seleziona una convenzione" }));
    }
  };

  return (
    <Fragment>
      <CustomHeaderPage title={pageTitle} disabledGoBack></CustomHeaderPage>
      <Box className="flex flex-grow flex-col mb-4">
        {/* <form onSubmit={handleSubmit}> */}
        <Paper>
          <Box className="flex flex-grow flex-row flex-wrap pt-4">
            <Typography variant="h6" className="px-4">
              {t("common:cliente")}
            </Typography>
          </Box>
          <Box className="p-2 pb-8">
            <Box className="flex flex-wrap">
              <Box className="w-full 2xl:w-3/12 xl:w-3/12 md:w-6/12 pl-2">
                {isConvenzioneGenerica ? (
                  <SelectConvenzioniGeneriche
                    id={"entiConvenzionati"}
                    onChange={(
                      desConvenzioneGenerica: DesConvenzioniGeneriche
                    ) => {
                      handleChangeEnte(desConvenzioneGenerica[convenzioneKey]);
                      setSelectedEnte(desConvenzioneGenerica.codConvGenerica);
                    }}
                    value={selectedEnte}
                    error={errors["progConvenzione"] ? true : false}
                    helperText={errors["progConvenzione"]}
                    fullData={true}
                  />
                ) : (
                  <SelectEntiConvenzionati
                    id={"entiConvenzionati"}
                    onChange={(ente: EntiConvenzionati) => {
                      handleChangeEnte(ente[convenzioneKey]);
                      if (convenzioneFlotteKey) {
                        setProgConvenzioneFlotte(ente[convenzioneFlotteKey]);
                      }
                      setSelectedEnte(ente.codCliente);
                    }}
                    value={selectedEnte}
                    error={errors["progConvenzione"] ? true : false}
                    helperText={errors["progConvenzione"]}
                    fullData={true}
                  />
                )}
              </Box>
            </Box>
            <Box className="flex flex-wrap">
              <Box className="w-full 2xl:w-3/12 xl:w-3/12 md:w-6/12 px-2">
                <TextField
                  fullWidth
                  error={errors["dataInizioValidita"] ? true : false}
                  helperText={errors["dataInizioValidita"]}
                  label={t("common:dataInizioValidita")}
                  size="small"
                  type="date"
                  id="dataInizioValidita"
                  value={
                    dataConvenzione?.dataInizioValidita
                      ? dataConvenzione?.dataInizioValidita
                      : ""
                  }
                  onChange={(event) => handleChangeDate(event.target)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
              <Box className="w-full 2xl:w-3/12 xl:w-3/12 md:w-6/12 px-2">
                <TextField
                  fullWidth
                  error={errors["dataFineValidita"] ? true : false}
                  helperText={errors["dataFineValidita"]}
                  label={t("common:dataFineValidita")}
                  size="small"
                  type="date"
                  id="dataFineValidita"
                  value={
                    dataConvenzione?.dataFineValidita
                      ? dataConvenzione?.dataFineValidita
                      : ""
                  }
                  onChange={(event) => handleChangeDate(event.target)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Paper>
        <Paper className="mt-4">
          <Box className="flex flex-grow flex-row flex-wrap pt-4">
            <Typography variant="h6" className="px-4">
              {"Criterio convenzione"}
            </Typography>
          </Box>
          <Box className="p-2">
            <Box className="flex flex-wrap items-start">
              <Box className="w-full 2xl:w-3/12 xl:w-3/12 md:w-6/12 px-2">
                <SelectStagionalita
                  id="stagionalita"
                  value={selectedStagionalita || null}
                  onChange={handleChangeStagionalita}
                  error={errors["stagionalita"] ? true : false}
                  helperText={errors["stagionalita"]}
                />
              </Box>
              <Box className="w-full 2xl:w-3/12 xl:w-3/12 md:w-6/12 px-2">
                <SelectTipiArticolo
                  id="tipoArticolo"
                  onChange={handleChangeTipiArticolo}
                  values={selectedTipiArticolo}
                  multiple
                  error={errors["tipoArticolo"] ? true : false}
                  helperText={errors["tipoArticolo"]}
                  codDivisione="1"
                />
              </Box>
              <Box className="w-full 2xl:w-3/12 xl:w-3/12 md:w-6/12 px-2 flex">
                <SelectProduttori
                  id="produttori"
                  label={t("common:inputproduttori")}
                  onChange={handleChangeProduttori}
                  values={selectedProduttori}
                  multiple
                />
                <IconButton
                  style={{
                    border: "none",
                    outline: "none",
                  }}
                  className="ml-2"
                  color="primary"
                  size="small"
                  onClick={(event) => {
                    setproduttoriPopperAnchorEl(event.currentTarget);
                    setProduttoriPopperOpen(! produttoriPopperOpen)
                  }}
                  >
                    <OfflineBolt />
                  </IconButton>
                <Popper open={produttoriPopperOpen} anchorEl={produttoriPopperAnchorEl} transition>
                  <div className="bg-white">
                    <div>
                      <Button
                        size="small"
                        className="mb-2"
                        onClick={() => {
                          setProduttoriPopperOpen(false)
                          setSelectedProduttori(environment.codiciProduttoriPrincipali)
                        }}
                        >
                        {t("Seleziona i produttori principali")}
                      </Button>
                    </div>
                    <div>
                      <Button
                        size="small"
                        onClick={() => {
                          setProduttoriPopperOpen(false)
                          setSelectedProduttori(
                            produttori
                              .map(produttore => produttore.codProduttore)
                              .filter(codProduttore => ! environment.codiciProduttoriPrincipali.includes(codProduttore))
                          )
                        }}
                        >
                        {t("Escludi i produttori principali")}
                      </Button>
                    </div>
                  </div>
                </Popper>
              </Box>
              <Box className="w-full 2xl:w-3/12 xl:w-3/12 md:w-6/12">
                <ArticlesSearch
                  onSelectedArticles={handleArticoliSelezionati}
                  defaultFuoriProd={'false'}
                />
              </Box>
              <Box className="pl-2 mt-2">
                <div style={{ display: "flex" }}>
                  <div
                    className={chipShow ? classes.showChip : classes.hiddenCip}
                  >
                    {selectedArticles.map((article) => {
                      return (
                        <Chip
                          key={article.codArticolo}
                          label={article.codArticolo}
                          onDelete={() => handleDeleteChip(article.codArticolo)}
                          className={"mr-2 mb-3"}
                        />
                      );
                    })}
                  </div>
                  {selectedArticles.length >= 1 ? (
                    chipShow ? (
                      <ExpandLess onClick={() => setChipShow(!chipShow)} />
                    ) : (
                      <ExpandMore onClick={() => setChipShow(!chipShow)} />
                    )
                  ) : null}
                </div>
              </Box>
            </Box>
            <Box>
              <Typography variant="h6" className="px-2">
                {"Extra sconti"}
              </Typography>
              <Box className="flex flex-wrap items-center">
                <div className="w-full md:w-1/4 px-2">
                  <FormGroup>
                    <FormControlLabel control={
                      <Checkbox
                        checked={showExtraSconti}
                        onChange={(event: any) => {
                          setShowExtraSconti(event.target.checked);
                        }}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    } label="Vedi solo sconti extra" />
                  </FormGroup>
                </div>
                <div className="w-full md:w-1/4 px-2">
                  <CustomSelect
                    data={[
                      {
                        id: 1,
                        description: 'Estivi',
                      },{
                        id: 2,
                        description: 'Invernali',
                      }
                    ]}
                    descriptionKey="description"
                    valueKey="id"
                    id="extraStagionalita"
                    label="Stagionalità sconti extra"
                    values={showExtraScontiStagionalita}
                    multiple
                    onChange={(value) => setShowExtraScontiStagionalita(value)}
                    disabled={!showExtraSconti}
                  />
                </div>
                <div className="w-full md:w-1/4 px-2">
                      <FormControlLabel
                          control={
                              <Checkbox
                                  color='primary'
                                  checked={showEscludiArticoliFuoriProduzione}
                                  onChange={(event: any) => {
                                    setShowEscludiArticoliFuoriProduzione(event.target.checked);
                                  }}
                                  disabled={!showExtraSconti}
                             />
                          }
                          label="Escludi articoli fuori produzione"
                      />
                </div>
              </Box>
            </Box>
            <Box className="flex justify-end pl-2 pr-2 pb-0 pt-0">
              {
                // eslint-disable-next-line
                selectedArticles.length > 0 ? (
                  <Button
                    onClick={() => {
                      resetDatagird();
                      setSelectedArticles([]);
                    }}
                    className="m-4 mr-0"
                  >
                    {t("convenzioniPrezziNetti:resetArticoli")}
                  </Button>
                ) : null
              }
              <Button onClick={() => {search()}} className="m-4 mr-0">
                {t("common:search")}
              </Button>
              {showExtraSconti || selectedStagionalita !== 0 || selectedTipiArticolo.length !== 0 || selectedProduttori.length !== 0 ? (
                <Button onClick={resetFiltriConv} className="m-4 mr-0">
                  {t("common:resetFilters")}
                </Button>
              ) : null}
            </Box>
          </Box>
        </Paper>
        <Grid item xs={12}>
          <Paper className="mt-4">
            <CustomRecordFound recordFound={criteriConvenzioni?.length || 0} />
            <Fragment>
              <DataGrid
                autoHeight
                rows={criteriConvenzioni || []}
                columns={columns}
                getRowId={(row) => {
                  return `${row.progCriterio}/${row.dettaglioScontiConvenzioni[0].progSconto}/${row.progConvenzione}/${row.codProduttore}/${row.codArticolo}/${row.codStagionalita}/${row.codTipoArticolo}/${row.dettaglioScontiConvenzioni[0].dataFineValidita}/${row.dettaglioScontiConvenzioni[0].dataInizioValidita}`;
                }}
                key={gridKey}
                disableColumnFilter
                hideFooter
                page={pageNumber}
                pageSize={pageSize}
                sortModel={sortColumns}
                onSortModelChange={(params: GridSortModelParams) => {
                  setSortColumns(params.sortModel as SortModel[]);
                }}
                getRowClassName={(params) =>
                  params.row.dettaglioScontiConvenzioni[0].percSconto <= 0
                    ? "highlighted-row-text"
                    : ""
                }
              />
              <CusomDataGridSelctPage
                pageSize={pageSize}
                pageNumber={pageNumber}
                numberOfPages={
                  criteriConvenzioni.length > 0
                    ? criteriConvenzioni.length / pageSize
                    : 1
                }
                changePageNumber={setPageNumber}
                changePageSize={setPagesize}
              />
            </Fragment>
          </Paper>
        </Grid>
        <CommonSpeedDial
          onSave={Auth.hasAccess("save-convenzioni") ? handleSave : undefined}
          disableSaveConfirmDialog
          updateData={[
            {
              taskName:
                environment.semaphoreJobsData.anagraficheUpdates.taskName,
              entities: [
                environment.semaphoreJobsData.anagraficheUpdates.entities
                  .criteriConvenzioni,
                environment.semaphoreJobsData.anagraficheUpdates.entities
                  .desConvenzioniGeneriche,
                environment.semaphoreJobsData.anagraficheUpdates.entities
                  .convenzioniDatiAggiuntivi,
                environment.semaphoreJobsData.anagraficheUpdates.entities
                  .prezziNettiConvenzioni,
                environment.semaphoreJobsData.anagraficheUpdates.entities
                  .dettaglioScontiConvenzioni,
                environment.semaphoreJobsData.anagraficheUpdates.entities
                  .testataConvenzioni,
              ],
            },
            {
              taskName:
                environment.semaphoreJobsData.centralDatabaseUpdates.taskName,
              entities: [
                environment.semaphoreJobsData.centralDatabaseUpdates.entities
                  .convenzioni,
              ],
            },
          ]}
          otherActions={
            Auth.hasAccess("save-convenzioni")
              ? [
                  {
                    icon: <AllOut />,
                    tooltip: t(`common:propagaSuTuttleLeLine`),
                    onClick: spredValue,
                  },
                  {
                    icon: <ControlPointDuplicate />,
                    tooltip: t(`common:assegnaConvenzioni`),
                    onClick: openDuplucate,
                    hidden: !isConvenzioneGenerica,
                  },
                  {
                    icon: <Schedule />,
                    tooltip: t("Proroga convenzioni"),
                    onClick: () => {
                      setProrogaConvenzioniOpen(true);
                    }
                  },
                  {
                    icon: <AssignmentReturn />,
                    tooltip: t("Decurta sconto"),
                    onClick: () => {
                      setDecurtaConvenzioniOpen(true);
                    }
                  },
                  {
                    icon: <EventBusy />,
                    tooltip: t("Scadenza massiva"),
                    onClick: () => {
                      setScadenzaMassivaConvenzioniOpen(true);
                    }
                  }
                ]
              : []
          }
        />
      </Box>
      <ConfirmAction data={confirmAction} setConfirmAction={setConfirmAction} />
      {isConvenzioneGenerica ? (
        <SelectDatiAggiuntiviConvenzione
          setOpen={setConfirmDialogeOpen}
          progconvenzione={progConvenzione}
          open={confirmDialogeOpen}
          onConfirmAction={confAction}
          selectedDealers={selectedDealers}
          setSelectedDealers={setSelectedDealers}
          dataProps={dataPdf}
          setData={setDataPdf}
        />
      ) : null}
      <ProrogaConvenzioniDialog
        defaultDate={dataConvenzione.dataFineValidita}
        open={prorogaConvenzioniOpen}
        setOpen={setProrogaConvenzioniOpen}
        handleProrogaConvenzioni={handleProrogaConvenzioni}
      />
      <DecurtaConvenzioniDialog
        open={decurtaConvenzioniOpen}
        setOpen={setDecurtaConvenzioniOpen}
        handleDecurtaConvenzioni={handleDecurtaConvenzioni}
      />
      <ScadenzaMassivaConvenzioniDialog
        defaultDate={dataConvenzione.dataFineValidita}
        open={scadenzaMassivaConvenzioniOpen}
        setOpen={setScadenzaMassivaConvenzioniOpen}
        handleScadenzaMassivaConvenzioni={handleScadenzaMassivaConvenzioni}
      />
    </Fragment>
  );
};
