import { Box, FormControl, FormControlLabel, Radio, RadioGroup, TextField } from "@material-ui/core";
import { FieldFilter } from "@store/common/common.types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SelectCategorieMerceologiche, SelectDisegni, SelectLivelliListino, SelectProduttori, SelectStagionalita, SelectTipiArticolo } from "@components/selects";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { getUltimaDataListino } from "@store/listini/listini.effects";
import moment from "moment";
import { CustomSelect } from "@components/selects/custom-select/CustomSelect";
import { getGlobalLarghezzeCerchi, getGlobalLarghezzeNominali, getGlobalSerieGomme, getGlobalIndiciDiCarico, getGlobalCodiciVelocita } from "@store/global/global.effects";
import { globalLarghezzeNominali, globalSerieGomme, globalLarghezzeCerchi, globalIndiciDiCarico, globalCodiciVelocita } from "@store/global/global.selector";

export interface ArticlesFiltersProps {
    filters: {
        filterFields: {
            [key: string]: FieldFilter;
        };
        activeFilters: {
            [key: string]: FieldFilter;
        };
    },
    onSelectChangeValue: (value: any, id: string) => void
    onTextFieldChangeValue: (e: React.SyntheticEvent<Element, Event>, id: string) => void
    onCheckBoxChangeValue: (e: React.ChangeEvent<HTMLInputElement>, checked: boolean, id: string) => void
    errors?: { [key: string]: string }
    showDataUltimoListino?: boolean,
    hiddenDivisions?: string[]
    disabledFields?: string[]
}

export function ArticlesFilters(props: ArticlesFiltersProps) {

    const dispatch = useAppDispatch();
    const { filters, onSelectChangeValue, onTextFieldChangeValue, onCheckBoxChangeValue, errors, showDataUltimoListino = false, hiddenDivisions = [], disabledFields = []} = props;

    const { t } = useTranslation(['common'])

    const [isPneumaticiSelected, setIsPneumaticiSelected] = useState<boolean>(true)
    const [isAccessoriSelected, setIsAccessoriSelected] = useState<boolean>(false)
    const [dataUltimoListino, setDataUltimoListino] = useState<string>('')
    const [localErrors, setLocalErrors] = useState<{ [key: string]: string }>({});

    useEffect(() => {
        dispatch(getGlobalLarghezzeNominali())
        dispatch(getGlobalSerieGomme())
        dispatch(getGlobalLarghezzeCerchi())
        dispatch(getGlobalIndiciDiCarico())
        dispatch(getGlobalCodiciVelocita())
    }, [dispatch]);
    const larghezzeNominali = useAppSelector(globalLarghezzeNominali);
    const serieGomme = useAppSelector(globalSerieGomme);
    const larghezzeCerchi = useAppSelector(globalLarghezzeCerchi);
    const indiciDiCarico = useAppSelector(globalIndiciDiCarico);
    const codiciDiVelocita = useAppSelector(globalCodiciVelocita);

    const [larghezzaNominale, setLarghezzaNominale] = useState<string>('')
    const [serieGomma, setSerieGomma] = useState<string>('')
    const [larghezzaCerchio, setLarghezzaCerchio] = useState<string>('')
    const [indiceDiCarico, setIndiceDiCarico] = useState<string>('')
    const [codiceDiVelocita, setCodiceDiVelocita] = useState<string>('')

    // empty filters values or reload filter on change divisione
    useEffect(() => {
        if (filters.filterFields?.divisione?.value) {
            filters.filterFields.tipoArticolo.value = '';
            filters.filterFields.categoriaMerceologica.value = '';
            setIsPneumaticiSelected(() => { return filters.filterFields?.divisione?.value === '1' });
            setIsAccessoriSelected(() => { return filters.filterFields?.divisione?.value === '2' });
            if (filters.filterFields?.divisione?.value !== '1') {
                // Empty pneumatici filters
                const filtersToEmpty = ['stagionalita', 'produttore', 'disegno', 'larghezzaNominale', 'serie', 'larghezzaCerchio', 'indiceDiCarico', 'codiceVelocita', 'runOnFlat', 'fourSeasons'];
                filtersToEmpty.forEach((item) => {
                    filters.filterFields[item].value = '';
                });
            }
        }
    }, [filters.filterFields?.divisione?.value]) // eslint-disable-line react-hooks/exhaustive-deps

    // reload ultima data listino on produttore change
    useEffect(() => {
        if (showDataUltimoListino) {
            if (filters.filterFields?.produttore?.value) {
                dispatch(getUltimaDataListino(filters.filterFields)).unwrap().then((res) => {
                    if (res.data) {
                        setDataUltimoListino(moment(new Date(res.data as string)).format("YYYY-MM-DD"));
                    } else {
                        setDataUltimoListino('');
                    }
                }).catch(() => {
                    setDataUltimoListino('');
                })
            } else {
                setDataUltimoListino('');
            }
        }
    }, [filters.filterFields?.livelloListino?.value, // eslint-disable-line react-hooks/exhaustive-deps
        filters.filterFields?.codArticolo?.value, // eslint-disable-line react-hooks/exhaustive-deps
        filters.filterFields?.tipoArticolo?.value, // eslint-disable-line react-hooks/exhaustive-deps
        filters.filterFields?.categoriaMerceologica?.value, // eslint-disable-line react-hooks/exhaustive-deps
        filters.filterFields?.stagionalita?.value, // eslint-disable-line react-hooks/exhaustive-deps
        filters.filterFields?.produttore?.value, // eslint-disable-line react-hooks/exhaustive-deps
        filters.filterFields?.disegno?.value, // eslint-disable-line react-hooks/exhaustive-deps
        filters.filterFields?.larghezzaNominale?.value, // eslint-disable-line react-hooks/exhaustive-deps
        filters.filterFields?.serie?.value, // eslint-disable-line react-hooks/exhaustive-deps
        filters.filterFields?.larghezzaCerchio?.value, // eslint-disable-line react-hooks/exhaustive-deps
        filters.filterFields?.indiceDiCarico?.value, // eslint-disable-line react-hooks/exhaustive-deps
        filters.filterFields?.codiceVelocita?.value, // eslint-disable-line react-hooks/exhaustive-deps
        filters.filterFields?.runOnFlat?.value, // eslint-disable-line react-hooks/exhaustive-deps
        filters.filterFields?.fuoriProd?.value]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        filters.filterFields.ultimaDataListino.value = dataUltimoListino;
    }, [dataUltimoListino]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (! filters.filterFields.larghezzaNominale.value && filters.filterFields.larghezzaNominale.value !== null) {
            setLarghezzaNominale('');
        }
        if (! filters.filterFields.serie.value && filters.filterFields.serie.value !== null) {
            setSerieGomma('');
        }
        if (! filters.filterFields.larghezzaCerchio.value && filters.filterFields.larghezzaCerchio.value !== null) {
            setLarghezzaCerchio('');
        }
        if (! filters.filterFields.indiceDiCarico.value && filters.filterFields.indiceDiCarico.value !== null) {
            setIndiceDiCarico('');
        }
        if (! filters.filterFields.codiceVelocita.value && filters.filterFields.codiceVelocita.value !== null) {
            setCodiceDiVelocita('');
        }
    }, [filters.filterFields.larghezzaNominale.value, filters.filterFields.serie.value, filters.filterFields.larghezzaCerchio.value, filters.filterFields.indiceDiCarico.value, filters.filterFields.codiceVelocita.value]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleTextFieldForSelectBlur = (value: string, id: string, listToCheck: any[], fieldToCheck: string, fieldToUse: string) => {
        if (value.trim() !== '') {
            const element: any = listToCheck.find(item => String(item[fieldToCheck]).trim() === value.trim());
            if (element) {
                setLocalErrorState(id, '');
                onSelectChangeValue(element[fieldToUse], id);
            } else {
                setLocalErrorState(id, t('common:validatorErrors:valueNotFound'));
                onSelectChangeValue(null, id);
            }
        } else {
            setLocalErrorState(id, '');
            onSelectChangeValue('', id);
        }
    }

    const setLocalErrorState = (id: string, value: string) => {
        setLocalErrors(prevState => {
            return {
                ...prevState,
                [id]: value
            }
        });
    }

    const hasLocalError = (id: string) => {
        return localErrors[id] ? true : false;
    }

    const getLocalError = (id: string) => {
        return localErrors[id];
    }

    const handleSelectChangeValue = (value: any, id: string) => {
        onSelectChangeValue(value, id);
    }

    const handleTextFieldChangeValue = (e: React.SyntheticEvent<Element, Event>, id: string) => {
        onTextFieldChangeValue(e, id)
    }

    // eslint-disable-next-line
    const handleCheckBoxChangeValue = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean, id: string) => {
        onCheckBoxChangeValue(e, checked, id);
    };

    return (
        <div className="p-4">
            <Box display='flex' flexDirection='row' flexWrap='wrap'>
                <Box className='px-2 w-full'>
                    <FormControl component="fieldset" disabled={disabledFields.includes('divisione')}>
                        <RadioGroup
                            aria-label="divisione"
                            name="controlled-radio-buttons-group"
                            row
                            value={filters.filterFields.divisione?.value}
                            onChange={(event: any) => { handleTextFieldChangeValue(event, filters.filterFields.divisione?.id) }}
                        >
                            {
                                ! hiddenDivisions.includes('1') ?
                                    <FormControlLabel value="1" control={<Radio />} label={(t('common:pneumatici'))} />
                                    : null
                            }
                            {
                                ! hiddenDivisions.includes('2') ?
                                    <FormControlLabel value="2" control={<Radio />} label={(t('common:accessori'))} />
                                    : null
                            }
                            {
                                ! hiddenDivisions.includes('3') ?
                                    <FormControlLabel value="3" control={<Radio />} label={(t('common:servizi'))} />
                                    : null
                            }
                        </RadioGroup>
                    </FormControl>
                </Box>
                <Box className='px-2 w-full 2xl:w-2/12 xl:w-3/12 md:w-6/12'>
                            <SelectLivelliListino
                                id={filters.filterFields.livelloListino?.id}
                                onChange={handleSelectChangeValue}
                                value={filters.filterFields.livelloListino?.value}
                                error={errors?.livelloListino ? true : false}
                                helperText={errors?.livelloListino}
                            />
                </Box>
                <Box className='px-2 w-full 2xl:w-2/12 xl:w-3/12 md:w-6/12'>
                    <TextField
                        fullWidth
                        size='small'
                        id={filters.filterFields.codArticolo?.id}
                        value={filters.filterFields.codArticolo?.value}
                        onChange={(event) => handleTextFieldChangeValue(event, filters.filterFields.codArticolo?.id)}
                        label={filters.filterFields.codArticolo?.label}
                        disabled={disabledFields.includes('codArticolo')}
                    />
                </Box>
                <Box className='px-2 w-full 2xl:w-2/12 xl:w-3/12 md:w-6/12'>
                    <SelectTipiArticolo
                        id={filters.filterFields.tipoArticolo?.id}
                        onChange={handleSelectChangeValue}
                        value={filters.filterFields.tipoArticolo?.value}
                        codDivisione={filters.filterFields?.divisione?.value}
                        disabled={disabledFields.includes('tipoArticolo')}
                    />
                </Box>
                <Box className='px-2 w-full 2xl:w-2/12 xl:w-3/12 md:w-6/12'>
                    <SelectCategorieMerceologiche
                        id={filters.filterFields.categoriaMerceologica?.id}
                        onChange={handleSelectChangeValue}
                        disabled={!(isPneumaticiSelected || isAccessoriSelected) || disabledFields.includes('categoriaMerceologica')}
                        value={filters.filterFields.categoriaMerceologica?.value}
                        codDivisione={filters.filterFields?.divisione?.value}
                    />
                </Box>
            </Box>
            <Box display='flex' flexDirection='row' flexWrap='wrap'>
                <Box className='px-2 w-full 2xl:w-2/12 xl:w-3/12 md:w-6/12'>
                    <SelectStagionalita
                        id={filters.filterFields.stagionalita?.id}
                        onChange={handleSelectChangeValue}
                        disabled={!isPneumaticiSelected || disabledFields.includes('stagionalita')}
                        value={filters.filterFields.stagionalita?.value}
                    />
                </Box>
                <Box className='px-2 w-full 2xl:w-2/12 xl:w-3/12 md:w-6/12'>
                    <SelectProduttori
                        id={filters.filterFields.produttore?.id}
                        onChange={handleSelectChangeValue}
                        disabled={!isPneumaticiSelected || disabledFields.includes('produttore')}
                        value={filters.filterFields.produttore?.value}
                        error={errors?.produttore ? true : false}
                        helperText={errors?.produttore}
                    />
                </Box>
                <Box className='px-2 w-full 2xl:w-2/12 xl:w-3/12 md:w-6/12'>
                    <SelectDisegni
                        id={filters.filterFields.disegno?.id}
                        onChange={handleSelectChangeValue}
                        disabled={!isPneumaticiSelected || disabledFields.includes('disegno')}
                        value={filters.filterFields.disegno?.value}
                        codDivisione={filters.filterFields?.divisione?.value}
                        codProduttore={filters.filterFields?.produttore?.value}
                        codTipoArticolo={filters.filterFields?.tipoArticolo?.value}
                        resetSelectedDisegno={() => {
                            onSelectChangeValue('', filters.filterFields.disegno?.id);
                        }}
                    />
                </Box>
                <Box className='px-2 w-full 2xl:w-2/12 xl:w-3/12 md:w-6/12'>
                    <TextField
                        fullWidth
                        size='small'
                        id={filters.filterFields.larghezzaNominale?.id}
                        value={larghezzaNominale}
                        onChange={(event) => {
                            setLocalErrorState(filters.filterFields.larghezzaNominale?.id, '');
                            setLarghezzaNominale(event.target.value);
                        }}
                        onBlur={(event) => {
                            handleTextFieldForSelectBlur(event.target.value, event.target.id, larghezzeNominali, 'larghezzaNominale', 'codLarghezzaNominale');
                        }}
                        label={t('common:larghezzaNominale')}
                        disabled={!isPneumaticiSelected || disabledFields.includes('larghezzaNominale')}
                        error={hasLocalError(filters.filterFields.larghezzaNominale?.id)}
                        helperText={getLocalError(filters.filterFields.larghezzaNominale?.id)}
                    />
                </Box>
                <Box className='px-2 w-full 2xl:w-2/12 xl:w-3/12 md:w-6/12'>
                    <TextField
                        fullWidth
                        size='small'
                        id={filters.filterFields.serie?.id}
                        value={serieGomma}
                        onChange={(event) => {
                            setLocalErrorState(filters.filterFields.serie?.id, '');
                            setSerieGomma(event.target.value);
                        }}
                        onBlur={(event) => {
                            handleTextFieldForSelectBlur(event.target.value, event.target.id, serieGomme, 'descrizione', 'codSerie');
                        }}
                        label={t('common:serie')}
                        disabled={!isPneumaticiSelected || disabledFields.includes('serie')}
                        error={hasLocalError(filters.filterFields.serie?.id)}
                        helperText={getLocalError(filters.filterFields.serie?.id)}
                    />
                </Box>
                <Box className='px-2 w-full 2xl:w-2/12 xl:w-3/12 md:w-6/12'>
                    <TextField
                        fullWidth
                        size='small'
                        id={filters.filterFields.larghezzaCerchio?.id}
                        value={larghezzaCerchio}
                        onChange={(event) => {
                            setLocalErrorState(filters.filterFields.larghezzaCerchio?.id, '');
                            setLarghezzaCerchio(event.target.value);
                        }}
                        onBlur={(event) => {
                            handleTextFieldForSelectBlur(event.target.value, event.target.id, larghezzeCerchi, 'larghezza', 'codLarghezzaCerchio');
                        }}
                        label={t('common:cerchio')}
                        disabled={!isPneumaticiSelected || disabledFields.includes('larghezzaCerchio')}
                        error={hasLocalError(filters.filterFields.larghezzaCerchio?.id)}
                        helperText={getLocalError(filters.filterFields.larghezzaCerchio?.id)}
                    />
                </Box>
                <Box className='px-2 w-full 2xl:w-2/12 xl:w-3/12 md:w-6/12'>
                    <TextField
                        fullWidth
                        size='small'
                        id={filters.filterFields.indiceDiCarico?.id}
                        value={indiceDiCarico}
                        onChange={(event) => {
                            setLocalErrorState(filters.filterFields.indiceDiCarico?.id, '');
                            setIndiceDiCarico(event.target.value);
                        }}
                        onBlur={(event) => {
                            handleTextFieldForSelectBlur(event.target.value, event.target.id, indiciDiCarico, 'indiceCarico', 'codIndiceCarico');
                        }}
                        label={t('common:indiceCarico')}
                        disabled={!isPneumaticiSelected || disabledFields.includes('indiceDiCarico')}
                        error={hasLocalError(filters.filterFields.indiceDiCarico?.id)}
                        helperText={getLocalError(filters.filterFields.indiceDiCarico?.id)}
                    />
                </Box>
                <Box className='px-2 w-full 2xl:w-2/12 xl:w-3/12 md:w-6/12'>
                    <TextField
                        fullWidth
                        size='small'
                        id={filters.filterFields.codiceVelocita?.id}
                        value={codiceDiVelocita}
                        onChange={(event) => {
                            setLocalErrorState(filters.filterFields.codiceVelocita?.id, '');
                            setCodiceDiVelocita(event.target.value);
                        }}
                        onBlur={(event) => {
                            handleTextFieldForSelectBlur(event.target.value, event.target.id, codiciDiVelocita, 'simbolo', 'codVelocita');
                        }}
                        label={t('common:codiceVelocita')}
                        disabled={!isPneumaticiSelected || disabledFields.includes('codiceVelocita')}
                        error={hasLocalError(filters.filterFields.codiceVelocita?.id)}
                        helperText={getLocalError(filters.filterFields.codiceVelocita?.id)}
                    />
                </Box>
                {
                    /* Si usa la stagionalità "Multistagionale"
                    <Box className='px-2 w-full 2xl:w-2/12 xl:w-3/12 md:w-6/12 flex items-center'>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    color='primary'
                                    checked={filters.filterFields.fourSeasons?.value === '1'}
                                    onChange={(event, checked) => handleCheckBoxChangeValue(event, checked, filters.filterFields.fourSeasons?.id)}
                                    disabled={!isPneumaticiSelected}
                                />
                            }
                            label={t(`common:4Seasons`)}
                        />
                    </Box>
                    */
                } 
                {              
                    /* Ricerca run on flat con checkbox
                    <Box className='px-2 w-full 2xl:w-2/12 xl:w-3/12 md:w-6/12 flex items-center'>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    color='primary'
                                    checked={filters.filterFields.runOnFlat?.value === '1'}
                                    onChange={(event, checked) => handleCheckBoxChangeValue(event, checked, filters.filterFields.runOnFlat?.id)}
                                    disabled={!isPneumaticiSelected || disabledFields.includes('runOnFlat')}
                                />
                            }
                            label={t(`common:rof`)}
                        />
                    </Box>
                    */
                }
                <Box className='px-2 w-full 2xl:w-2/12 xl:w-3/12 md:w-6/12 flex items-center'>
                    <CustomSelect
                        label={t(`common:rof`)}
                        data={[
                            {
                                value: 'true',
                                description: t('common:yes')
                            },
                            {
                                value: 'false',
                                description: t('common:no')
                            }
                        ]}
                        descriptionKey={'description'}
                        valueKey={'value'}
                        id={filters.filterFields.runOnFlat?.id}
                        onChange={handleSelectChangeValue}
                        value={filters.filterFields.runOnFlat?.value}
                        disabled={disabledFields.includes('runOnFlat')}
                    />
                </Box>
                <Box className='px-2 w-full 2xl:w-2/12 xl:w-3/12 md:w-6/12 flex items-center'>
                    <CustomSelect
                        label={t(`common:flgfuoriProd`)}
                        data={[
                            {
                                value: 'true',
                                description: t('common:yes')
                            },
                            {
                                value: 'false',
                                description: t('common:no')
                            }
                        ]}
                        descriptionKey={'description'}
                        valueKey={'value'}
                        id={filters.filterFields.fuoriProd?.id}
                        onChange={handleSelectChangeValue}
                        value={filters.filterFields.fuoriProd?.value}
                        disabled={disabledFields.includes('flgfuoriProd')}
                    />
                </Box>
                {
                    showDataUltimoListino ?
                        <Box className='px-2 w-full 2xl:w-2/12 xl:w-3/12 md:w-6/12 flex items-center'>
                            <TextField
                                type='date'
                                fullWidth
                                size='small'
                                id={filters.filterFields.ultimaDataListino?.id}
                                value={dataUltimoListino}
                                onChange={(event) => setDataUltimoListino(event.target.value)}
                                label={filters.filterFields.ultimaDataListino?.label}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={true}
                            />
                        </Box>
                    : null
                }
            </Box>
        </div>
    );
}
